import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { ProjectModule } from '../../../shared/modules/project.module';
import { SharedModule } from '../../../shared/modules/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MessageServiceModalComponent } from './components/message-service-modal/message-service-modal.component';
import { MasterManualSettingsComponent } from './components/settings/manual-settings/master-manual-settings.component';
import { MasterSettingsComponent } from './components/settings/master-settings.component';
import { MasterAuthGuard } from './guards/master-auth-guard';

@NgModule({
  imports: [
    AppRoutingModule,
    ProjectModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true,
    }),
  ],
  declarations: [
    // Components
    AppComponent,
    MasterManualSettingsComponent,
    MasterSettingsComponent,
    MessageServiceModalComponent,
  ],
  providers: [
    // guards
    MasterAuthGuard,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
