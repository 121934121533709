<div class="my-login">
  <h2 class="custom-header">{{ 'master.settings' | translate }}</h2>

  <div class="mt-4 row justify-content-center">
    <div
      class="ml-2 card settings-card pointer"
      (click)="onManualSettingsClicked()"
    >
      <div class="card-body">
        <div class="text-center font-weight-bold">
          {{ 'master.manual-settings' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4 mr-1 float-right">
    <button
      mdbBtn
      [block]="true"
      class="my-4 custom-btn"
      type="anmelden"
      (click)="returnToActiveMeeting()"
    >
      {{ 'master.active-meeting' | translate }}
    </button>
  </div>
</div>
