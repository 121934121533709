import { App } from 'projects/shared/environments/app';

export const environment = {
  app: App.MASTER_APP,
  production: false,
  apiURL: '/backend/',
  masterURL: 'https://master-bhdev.weptun.de/main/',
  participantURL: 'https://participant-bhdev.weptun.de/',
  hadURL: 'https://had-bhdev.weptun.de',
  websocketURL: 'wss://master-bhdev.weptun.de/backend/ws',
  playlistBaseUrl: 'https://static.beyond.host/music',
  title: 'Master App',
  useHeartbeat: true,
};
