<div class="my-page">
  <div class="row">
    <div
      class="ml-4 mt-auto mb-auto"
      style="cursor: pointer"
      (click)="onBackBtnClicked()"
    >
      <div class="row">
        <i class="fas fa-chevron-left fa-lg"></i>
        <h5>{{ 'global.back' | translate }}</h5>
      </div>
    </div>
    <!-- <div class="col-11"> -->
    <h2 class="custom-header" style="margin-left: 1.5rem">
      {{ 'master.settings' | translate }}
    </h2>
    <!-- </div> -->
  </div>
  <!-- Input  -->
  <div class="card" style="padding: 2rem">
    <!-- Hotel-Id -->
    <div class="md-form mt-0">
      <input
        mdbInput
        type="text"
        id="hotelId"
        class="form-control"
        [(ngModel)]="hotelId"
      />
      <label for="hotelId">{{ 'global.location-id' | translate }}</label>
    </div>

    <!-- Room-Id -->
    <div class="md-form mt-0">
      <input
        mdbInput
        type="text"
        id="roomId"
        class="form-control"
        [(ngModel)]="roomId"
      />
      <label for="roomId">{{ 'global.room-id' | translate }}</label>
    </div>

    <!-- Secret -->
    <div class="md-form mt-0">
      <input
        mdbInput
        [type]="'password'"
        id="secret"
        class="form-control"
        aria-describedby="defaultRegisterFormPasswordHelpBlock"
        [(ngModel)]="secret"
      />
      <label for="secret">{{ 'global.secret' | translate }}</label>
    </div>

    <!-- Save button -->
    <div class="float-right">
      <button
        mdbBtn
        type="button"
        class="custom-btn"
        mdbWavesEffect
        (click)="onSaveButtonClicked()"
      >
        {{ 'global.save' | translate }}
      </button>
    </div>
  </div>
</div>
