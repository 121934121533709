import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { BhToastService } from 'projects/shared/services/bh-toast.service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Room } from '../../../../shared/models/room.model';
import { AuthService } from '../../../../shared/services/auth.service';
import { EnvironmentService } from '../../../../shared/services/environment.service';
import { LocalStorageService } from '../../../../shared/services/local-storage.service';

@Injectable()
export class MasterAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _authService: AuthService,
    private _environmentService: EnvironmentService,
    private toastService: BhToastService,
    private localStorageService: LocalStorageService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this._authService.loggedIn) {
      return this.loadMasterAppData();
    }
    return this._authService.checkCredentials().pipe(
      switchMap(() => {
        if (this._environmentService.checkSettingsMaster()) {
          return this.loadMasterAppData();
        } else {
          this.router.navigate(['/settings/manual']);
          return of(false);
        }
      }),
      catchError((_) => {
        this.toastService.warning('global.missing-info');
        this.router.navigate(['/settings/manual']);
        return of(false);
      })
    );
  }

  private loadMasterAppData(): Observable<boolean> {
    return this._environmentService.loadMasterAppData().pipe(
      map((response) => {
        if (response) {
          const room: Room = response[2];
          this.localStorageService.saveRoomName(room.name);
          this._environmentService.startWebSocketConnection(true);
          this._authService.login();
          return true;
        }
        return false;
      }),
      catchError((error) => {
        console.log(error);
        // Start a new meeting if there is none
        if (
          error.status === 404 &&
          error.error.includes('has no active meeting.')
        ) {
          this._environmentService.retrieveData().subscribe(
            (_) => {
              this._authService.login();
            },
            (_) => {
              this.toastService.warning('master.start-meeting-error');
            }
          );
        } else {
          this.toastService.warning('global.load-data-error');
        }

        return of(false);
      })
    );
  }
}
