import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BhToastService } from 'projects/shared/services/bh-toast.service';
import { SubscriptionSTOMPKey } from '../../../../../../shared/models/subscriptionSTOMPKey.model';
import { ApplicationWebsocketClient } from '../../../../../../shared/services/application-websocket-client';
import { AuthService } from '../../../../../../shared/services/auth.service';
import { EnvironmentService } from '../../../../../../shared/services/environment.service';
import { LocalStorageService } from '../../../../../../shared/services/local-storage.service';
import { ResettableService } from '../../../../../../shared/services/resettable.service';

@Component({
  selector: 'app-master-manual-settings',
  templateUrl: './master-manual-settings.component.html',
  styleUrls: ['./master-manual-settings.component.scss'],
})
export class MasterManualSettingsComponent implements OnInit {
  public hotelId: string;
  public roomId: string;
  public secret: string;

  constructor(
    private localStorageService: LocalStorageService,
    private location: Location,
    private toastService: BhToastService,
    private authService: AuthService,
    private router: Router,
    private environmentService: EnvironmentService,
    private resettableService: ResettableService,
    private applicationWebsocketClient: ApplicationWebsocketClient
  ) {}

  public ngOnInit(): void {
    this.hotelId = this.localStorageService.loadHotel() ?? '';
    this.roomId = this.localStorageService.loadRoom() ?? '';
  }

  public onBackBtnClicked(): void {
    this.location.back();
  }

  public onSaveButtonClicked(): void {
    const oldHotelId = this.localStorageService.loadHotel();
    this.localStorageService.saveHotel(this.hotelId);
    const oldRoomId = this.localStorageService.loadRoom();
    this.localStorageService.saveRoom(this.roomId);

    // Clearup of old websockets
    this.resettableService.resetNow.next(true);
    // If the MasterApp (tablet) is 'paired' with a new room (wrong room)
    // or a new hotel (wrong hotel) => Close (possible) open room WebSocket Connection
    if (oldRoomId) {
      if (oldRoomId !== this.localStorageService.loadRoom()) {
        this.environmentService.disconnectWebSocketConnection();
      }
    }
    if (oldHotelId) {
      if (oldHotelId !== this.localStorageService.loadHotel()) {
        this.environmentService.disconnectWebSocketConnection();
      }
    }

    // Delete the 'known' open websocket connection to room
    this.applicationWebsocketClient.disconnectOfSubscription(
      SubscriptionSTOMPKey.ROOM
    );

    this.authService.loginMaster(this.secret).subscribe(
      () => {
        // Verify that the room exists for the hotel where the cookie is assigned to
        this.environmentService.getLoadedRoom().subscribe(
          (room) => {
            this.toastService.success('global.login-success');
            this.localStorageService.saveRoomName(room.name);

            // Establishing new room websocket connection
            this.environmentService.startWebSocketConnection(true);
            this.router.navigateByUrl('/master/home');
          },
          (error) => {
            this.toastService.error('global.room-not-exists');
          }
        );
      },
      (error) => {
        this.toastService.error('master.wrong-secret');
        console.log(error);
      }
    );
  }
}
