import { Component } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-message-service-modal',
  templateUrl: './message-service-modal.component.html',
  styleUrls: ['./message-service-modal.component.scss'],
})
export class MessageServiceModalComponent {

  messageText: string = '';

  public confirmClicked$: Subject<string> = new Subject();

  constructor(private _modalRef: MDBModalRef) {}

  public onCancelButtonClicked(): void {
    this.confirmClicked$.next(null);
    this._modalRef.hide();
  }

  public onConfirmButtonClicked(): void {
    this.confirmClicked$.next(this.messageText);
    this._modalRef.hide();
  }
}
