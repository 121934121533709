<form #messageServiceTextForm="ngForm" (ngSubmit)="onConfirmButtonClicked()">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title w-100">
        {{ 'global.message-service' | translate }}
      </h4>
    </div>
    <div class="modal-body">
      <div class="md-form mt-0">
        <input
          type="text"
          id="messageText"
          name="messageText"
          #messageTextForm="ngModel"
          class="form-control"
          mdbInput
          [(ngModel)]="messageText"
          [minlength]="1"
          [maxLength]="70"
          required
        />
        <label for="messageText">
          {{ 'global.message-service-modal.title' | translate }}
          <span
            [ngClass]="{
              'text-color-invalid-field': messageTextForm.errors,
              'text-color-valid-field': !messageTextForm.errors
            }"
          >
            *
          </span>
        </label>
      </div>
    </div>
    <div class="modal-footer">
      <button
        mdbBtn
        type="button"
        class="custom-btn decline-button"
        mdbWavesEffect
        (click)="onCancelButtonClicked()"
      >
        {{ 'global.cancel' | translate }}
      </button>
      <button
        mdbBtn
        type="submit"
        class="custom-btn accept-button"
        mdbWavesEffect
        [disabled]="messageTextForm.invalid"
      >
        {{ 'global.okay' | translate }}
      </button>
    </div>
  </div>
</form>
