import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-master-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isFullyBrowser = 'fully' in window;

  @BlockUI() public blockUI: NgBlockUI;

  private TITLE = environment.title;

  constructor(private titleService: Title) {}

  public ngOnInit(): void {
    this.titleService.setTitle(this.TITLE);
  }
}
