import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { sharedRoutes } from '../../../shared/routes/route.routing';
import { MasterManualSettingsComponent } from './components/settings/manual-settings/master-manual-settings.component';
import { MasterSettingsComponent } from './components/settings/master-settings.component';
import { MasterAuthGuard } from './guards/master-auth-guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'main',
        loadChildren: () =>
          import('./modules/main/main.module').then((m) => m.MainModule),
        canActivate: [MasterAuthGuard],
      },
      {
        path: 'settings',
        component: MasterSettingsComponent,
        pathMatch: 'full',
      },
      {
        path: 'settings/manual',
        component: MasterManualSettingsComponent,
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'main',
        pathMatch: 'full',
      },
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot([...sharedRoutes, ...routes], {
      // Do only tracing use for debugging
      enableTracing: false,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
