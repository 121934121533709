import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService } from '../../../../../shared/services/environment.service';

@Component({
  selector: 'app-master-settings',
  templateUrl: './master-settings.component.html',
  styleUrls: ['./master-settings.component.scss'],
})
export class MasterSettingsComponent {
  constructor(
    private router: Router,
    private environmentService: EnvironmentService
  ) {}

  public returnToActiveMeeting(): void {
    if (this.environmentService.checkSettingsMaster()) {
      this.router.navigateByUrl('main/home');
    }
  }

  public onManualSettingsClicked(): void {
    this.router.navigateByUrl('/settings/manual');
  }
}
